import React, { memo, useEffect, useRef, useState } from 'react';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../contexts/cart';
import { useAwaitQuery } from '../../../../aem-core-components/utils/hooks';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import useCartAnalytics from '../../../../hooks/analytics/useCartAnalytics';
import { useDidMount } from '../../../../hooks/useDidMount';
import { usePricing } from '../../../../hooks/usePricing';
import { useAtp } from '../../../cap/hooks/useATP';
import Carousel from '../../modules/alternateInventory/carousel/Carousel';
import AddToCartProductTileReact from '../../modules/productTile/addToCartProductTileReact/AddToCartProductTileReact';
import { getProductList } from '../../../../aem-core-components/actions/product/actions';
import QUERY_PRODUCT_LIST_STATIC from '../../../../aem-core-components/queries/query_product_list_static';
import { showGreenLeafTag } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import './productcarousal.scss';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { PRODUCT_CARDS_MOBILE_RESPONSIVE } from '../../constants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { getDynamicProducts } from '../dynamicSeasonalProducts/api/getDynamicProducts';
import { setAccessTokenSession } from '../../modules/dataBricks/api/getDataBricks';
import { dynamicSeasonalProductsConstant } from '../dynamicSeasonalProducts/dynamicSeasonalProductsConstant';
import isObjectWithKeys from '../../../../aem-core-components/utils/isObjectWithKeys';

const ProductCarousal = () => {
    const [{ cidPcList }] = useCartState();
    const { getProductPrices } = usePricing();
    const [{ isProfileLoaded }] = useUserContext();
    const [{ sendAddToCartAnalytics }] = useCartAnalytics();
    const { isRentalDetailsAvailable, fetchLocationCoordinates } = useCheckLocationEmpty();
    const { handleATPCart } = useAtp();
    const staticProductsListQuery = useAwaitQuery(QUERY_PRODUCT_LIST_STATIC);
    const didMount = useDidMount();
    const currentProductsList = useRef(null);
    const [showDynamicCarousal, setshowDynamicCarousal] = useState(true);
    const [dynamicCarousal, setDynamicCarousal] = useState([]);
    const [staticCarousal, setStaticCarousal] = useState([]);
    const [productsWithEcpcData, setProductsWithEcpcData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { localLat, localLong } = fetchLocationCoordinates();
    const localCompanyId = parseInt(localStorage?.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
   
    useEffect(() => {
        const { layout, productsdata: staticProductsdata } =
        currentProductsList?.current?.parentElement?.dataset || {};
        if (currentProductsList?.current) {
            if (layout === 'static' || !showDynamicCarousal) {
                setshowDynamicCarousal(false);
                if (staticProductsdata) {
                    try {
                        setStaticCarousal(JSON.parse(staticProductsdata));
                    } catch (error) {
                        logError('Error parsing product data:', error);
                    }
                }
            }   
        }
    }, [didMount, showDynamicCarousal]);

    useEffect(()=>{
        const { layout} =currentProductsList?.current?.parentElement?.dataset || {};
        if(layout!=="static" && !showDynamicCarousal){
            setshowDynamicCarousal(true);
        }   
    },[cidPcList])

    // Fetch data from API
    const fetchDynamicSesonalOffering = async (
        month,
        maxdistance,
        maxresults,
        utlscore,
        latitute,
        longitude,
        setCountryCode
    ) => {
        try {
            setIsLoading(true);
            await setAccessTokenSession();
            const { data } = await getDynamicProducts(
                month,
                maxdistance,
                maxresults,
                utlscore,
                latitute,
                longitude,
                setCountryCode
            );
            const seasonalOfferings = data?.data?.SeasonalOfferings || [];
            if (!seasonalOfferings || seasonalOfferings?.length === 0) {
                setDynamicCarousal([]);
                setshowDynamicCarousal(false)
            } else {
                setDynamicCarousal(seasonalOfferings);
            }
        } catch (error) {
            setDynamicCarousal([]);
            logError(error, false, 'fetchDynamicSesonalOffering');
        }
        finally{
            setIsLoading(false);
        }
    };

    const callRatesForStaticItems = async () => {
        if (staticCarousal?.length === 0) return;
        const skuList = staticCarousal?.map(item => item?.catclass);
        setIsLoading(true);
        try {
            const { productDetails } = await getProductList({
                productListQuery: staticProductsListQuery,
                skuList,
                pageSize: skuList?.length,
                handleATPCart
            });

            const mergedData = staticCarousal?.map(product => {
                const matchedECPC = productDetails?.find(ecpc => ecpc?.catclass === product?.catclass);
                return {
                    ...product,
                    inventory: matchedECPC?.ec_pc_inventory || '{}',
                    isgreenleaf: showGreenLeafTag(product?.isgreenleaf)
                };
            });
            setProductsWithEcpcData(mergedData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setProductsWithEcpcData([]);
            logError('Error fetching ECPC data:', error);
        }
    };

    const getCountryCode = countryName => {
        switch (countryName.toLowerCase()) {
            case 'canada':
                return 2;
            default:
                return 1;
        }
    };

    // Fetch  lat long from Adobe Target
    const getTargetLocationDetails = async () => {
        if (currentProductsList?.current) {
            const { disablegeo } = currentProductsList?.current?.parentElement?.dataset || {};
            try {
                if (disablegeo) {
                    return { longitude: localLong, latitude: localLat, companyId: localCompanyId };
                }
                if (!localLat || !localLong) {
                    const geoLocationDetails = await new Promise((resolve, reject) => {
                        adobe?.target?.getOffer({
                            mbox: 'mbox-geo-rental-page',
                            success: function (offer) {
                                if (offer && offer?.length > 0) {
                                    const geoLocationOffer = offer?.[0];
                                    let geoLocationDetails = geoLocationOffer?.content?.[0];
                                    geoLocationDetails.companyId = getCountryCode(geoLocationDetails?.country);
                                    resolve(geoLocationDetails); // Resolve with the details
                                } else {
                                    resolve(null); // Resolve with null if no offer
                                }
                            },
                            error: function (status, error) {
                                reject(error); // Reject in case of an error
                            }
                        });
                    });
                    return geoLocationDetails; // Return the resolved details
                } else {
                    return { longitude: localLong, latitude: localLat, companyId: localCompanyId };
                }
            } catch (error) {
                logError(error, false, 'getTargetLocationDetails');
                return {};
            }
        }
    };

    // Get all the data and  pass params to API Fetch call
    const getCarousalData = async () => {
        try {
            if (isProfileLoaded) {
                //test for location adobe target
                const targetLocatons = await getTargetLocationDetails();
                const setLocationLat = targetLocatons?.latitude;
                const setLocatonLong = targetLocatons?.longitude;
                const setCountryCode = targetLocatons?.companyId;
                const currentMonth = new Date().getMonth() + 1;
                const { maxdistance, maxresults, utlscore } =
                    currentProductsList?.current?.parentElement?.dataset || {};

                const {
                    maxdistance: maxDistanceConst,
                    maxresults: maxResultsConst,
                    utlscore: utlsCoreConst
                } = dynamicSeasonalProductsConstant;
                const maxDistance = maxdistance || maxDistanceConst;
                const maxResults = maxresults || maxResultsConst;
                const utlScore = utlscore || utlsCoreConst;

                if (!setLocationLat || !setLocatonLong) {
                    return;
                }
                await fetchDynamicSesonalOffering(
                    currentMonth,
                    maxDistance,
                    maxResults,
                    utlScore,
                    setLocationLat,
                    setLocatonLong,
                    setCountryCode
                );
            }
        } catch (error) {
            logError(error, false, 'getCarousalData');
        }
    };

    const addToCartClickAnalytics = cardData => {
        sendAddToCartAnalytics(cardData);
    };
    
    const trackAddToCartAnalytics = (item = {}) => {
        try {
            if (isObjectWithKeys(item)) {
                sendAddToCartAnalytics(item, true);
            }
        } catch (error) {
            logError(error, false, 'trackAddToCartAnalytics');
        }
    };

    const getAllDynamicCarouselItems = dynamicCarousal => {
        return dynamicCarousal?.map((singleDynamicProduct, index) => {
            const {
                catclass,
                productimageurl,
                producttitle,
                producturl,
                metadescription,
                metatitle,
                ec_pc_inventory,
                disableaddtocartoption,
                showonlinecatalog,
                categoryname,
                parentCategory,
                categories,
                isgreenleaf,
                greenleafdata
            } = singleDynamicProduct;

            const cardData = {
                catclass,
                productimageurl,
                producttitle,
                producturl,
                metadescription,
                metatitle,
                catsku: catclass,
                inventory: ec_pc_inventory || '{}',
                disableaddtocartoption,
                showonlinecatalog,
                listName: VARIABLE_CONFIG?.PRODUCT_CARD?.SEASONAL_LIST_NAME,
                categoryname,
                parentCategory,
                categoryname: categories?.[0]?.name || categoryname,
                parentCategory: categories?.[1]?.name || parentCategory,
                subcategoryname: categories?.[2]?.name,
                position: index + 1,
                index: index,
                isgreenleaf: showGreenLeafTag(isgreenleaf),
                greenleafdata: greenleafdata
            };
            return (
                <AddToCartProductTileReact
                    key={catclass}
                    cardData={cardData}
                    handleAddToCartClickAnalytics={trackAddToCartAnalytics}
                />
            );
        });
    };

    const getAllStaticCarousalItems = () => {
        if (!productsWithEcpcData || productsWithEcpcData.length === 0) return null;
        return productsWithEcpcData.map(item => (
            <AddToCartProductTileReact
                key={item?.catclass}
                cardData={item}
                handleAddToCartClickAnalytics={() => addToCartClickAnalytics(item)}
            />
        ));
    };

    useEffect(() => {
        if (staticCarousal?.length > 0 && isProfileLoaded) {
            callRatesForStaticItems();
        }
    }, [isProfileLoaded, staticCarousal]);
    useEffect(() => {
        if (showDynamicCarousal && isProfileLoaded) {
            getCarousalData();
        }
    }, [isProfileLoaded, localLong, localLat, showDynamicCarousal]);

    const getAllFetchRates = (dataArray) =>{
        try {
            const fetchRates =  () => {
                getProductPrices(dataArray, VARIABLE_CONFIG?.PAGE_TYPE?.EQUIPMENT_AND_TOOLS_PAGE);
            };
            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && dataArray?.length > 0) {
                    fetchRates();
                }
            }
        } catch (error) {
            logError(error, false, 'Dynamic Product carousal');
        } 
    }

    useEffect(() => {
        getAllFetchRates(productsWithEcpcData)
    }, [cidPcList, isProfileLoaded, productsWithEcpcData]);
    
    useEffect(() => {
        getAllFetchRates(dynamicCarousal)
    }, [cidPcList, isProfileLoaded, dynamicCarousal]);

    useEffect(() => {
        const productCarousalClosest = currentProductsList?.current?.closest('.productSlider');
        const shimmerDiv = productCarousalClosest?.querySelector('.row--popular-shimer');
        if (shimmerDiv) {
            if (isLoading) {
                shimmerDiv.style.display = 'flex';
                currentProductsList.current.style.display = 'none';
            } 
            else {
                shimmerDiv.style.display = 'none';
                currentProductsList.current.style.display = 'block';
            }
        }
    }, [isLoading, dynamicCarousal, cidPcList]);
    return (
        <div ref={currentProductsList}>
            
            {!isLoading && (
                <Carousel
                    mobileResponsive={PRODUCT_CARDS_MOBILE_RESPONSIVE}
                    disableDotsControls={false}
                    totalItem={
                        dynamicCarousal && dynamicCarousal?.length > 0
                            ? dynamicCarousal?.length
                            : productsWithEcpcData?.length
                    }
                    carouselWrapClasses={'carousel--products'}>
                    {dynamicCarousal && dynamicCarousal.length > 4
                        ? getAllDynamicCarouselItems(dynamicCarousal)
                        : getAllStaticCarousalItems(productsWithEcpcData)}
                </Carousel>
            )}
        </div>
    );
};

export default memo(ProductCarousal);
