export const getCookie = (name) =>{
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null; // Return null if the cookie is not found
}

export const USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit',
    PUNCHOUT: 'punchout'
};

export const getUserType =() =>{
    return sessionStorage.getItem('userType');
}


const addGrayBgClass = (el) => {
    if (el.className.indexOf('tracker_background') === -1) {
        el.className = `${el.className} tracker_background`;
    }
};
  
const checkBgClass = () => {
    const rentalTracker = document.querySelector('.rental-tracker');
    if (rentalTracker) {
        addGrayBgClass(document?.body);
    }
};
  
if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    checkBgClass();
} else {
    document.addEventListener('DOMContentLoaded', checkBgClass);
}
  

export const trapFocus = element => {
    var focusableEls = element.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), h1, h2, h3, h4, h5, h6, strong, b'
    );
    var firstFocusableEl = focusableEls[0];
    //firstFocusableEl.focus();
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    element.addEventListener('keydown', function (e) {
        var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
};