import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';
import { getSignInUrl } from '../global/utils/commonUtils';

const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const authorityType = useCheckAuthorityType();
const developmentBasePath = '/content/sunbeltrentals/us/en_US';
const p2pPath = '/procure-to-pay/punchout';

let grWorkflowSelector = document.querySelector('.gr__workflow');
let cashWorkflowSelector = document.querySelector('.cash__workflow');
let ccaWorkflowSelector = document.querySelector('.cca__workflow');

const getHomePagePath = () => {
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return isDevelopment ? `${developmentBasePath}${p2pPath}.html` : `${p2pPath}/`;
    }
    return isDevelopment ? `${developmentBasePath}.html` : '/';
};

const getCartPagePath = () => {
    if (authorityType === AUTHORITY_TYPE.P2P) {
        return isDevelopment ? `${developmentBasePath}${p2pPath}/cart.html` : `${p2pPath}/cart/`;
    }
    return isDevelopment ? `${developmentBasePath}/cart.html` : '/cart/';
};

export default {
    mountingPoints: {
        accountContainer: '.miniaccount__body',
        addressBookContainer: '.addressbook__body',
        authBarContainer: 'aside.navigation__root #miniaccount',
        cartTrigger: '.minicart__trigger',
        minicart: '.minicart__body',
        navPanel: 'aside.navigation__root',
        bundleProductOptionsContainer: '#bundle-product-options',
        accountDetails: '.accountdetails__body',
        resetPasswordPage: '.resetpassword__body',
        checkoutPage: '.checkout',
        backToCartCta: '#back-to-cart-header-btn',
        pdpRates: '.pdp__rates_react',
        search: '.search__coveo',
        locationPage: '.location_landing_page',
        pdpSelectLocation: '.select__location',
        addToCartTile: '.seasonal__carousel-productcard',
        plpPage: '.plp__search__page',
        contactUsMarketo: '.contact-us__Marketo',
        frequentlyRentals: '.frequently_rentals_tab',
        yourFavorites: '.your_favorites__tab',
        recentlyViewed: '.recently_viewed_items',
        getGeoLoation: '.get__global__geolocation',
        searchResultsPage: '.search__results__page',
        combinedTabs: '.combined_tabs',
        punchoutUserSection: '.punchout_user_section',
        searchCoveoInput: '.search__coveo__input',
        timeoutNotification: '.timeout__notification',
        wiresCrossed: '.error__wires__crossed',
        notificationBanner: '.notification__banner',
        locDistanceWarningBanner: '.inventorycheck__banner',
        dstTrainerMap: '.dst_trainer_page',
        pdpAlternateInventory: '.pdp__alternate-inventory',
        pdpAccessoriesAndAddOnsCorousel: '.pdp_accessories_addons_tab',
        p2pFavourites: '.p2p__favourite-lists',
        favouritesDropDown: '.P2P_React_Mount',
        p2pQuotes: '.p2p__quotes',
        getUtilityBanner: '.get__global__utilitybanner',
        p2pPOList: '.p2p__purchaseorders-lists',
        ccaWorkflow: '.cca__workflow',
        ccaApplicationStatus: '#cca_application_status',
        ccaWorkflowHeader: '.cca_workflow_header',
        capHeader: '.cap__header',
        capSideHeader: '.cap-header-aem__right-content',
        guidedRegWorkflow: '.gr__workflow',
        cashWorkflow: '.cash__workflow',
        cashWorkflowApplicationStatus: '.applicationStatus__configs',
        grApplicationError: '.gr_application_status_error',
        cartPage: '.cart-page',
        capHeaderBottomSticky: '.ldp_cap_bottom_sticky',
        p2pExistingQuotes: '.p2p__findExistingQuotes',
        p2pManagePurchaseOrder: '.p2p__managePurchaseOrder',
        P2PSideNavigation: '.left__navigation__menu',
        punchoutBanner: '.get__global__p2pretailbanner',
        p2pMyFavoritesCarousal: '.p2p__myFavoritesCarousal',
        ldpAlertMessage: '.ldp_alert_message',
        recommendationCarousal: '.recommendation__Carousal',
        heroSearchBox: '.herosearch__box',
        dynamicSeasonalProducts: '.dynamic-seasonal-products',
        productCarousal:'.flex_product_slider'
        
    },
    pagePaths: {
        addressBook: '/content/venia/us/en/my-account/address-book.html',
        baseUrl: document.querySelector('body').dataset.storeRootUrl,
        accountDetails: '/content/venia/us/en/my-account/account-details.html',
        resetPassword: '/content/venia/us/en/reset-password.html',
        checkoutPage: isDevelopment ? '/content/sunbeltrentals/us/en_US/checkout-page.html' : '/checkout-page/',
        productPage: isDevelopment
            ? '/content/sunbeltrentals/us/en_US/equipment-rental/product-page.html'
            : '/equipment-rental/',
        homePage: getHomePagePath(),
        searchPage: isDevelopment ? '/content/sunbeltrentals/us/en_US/search/search.html' : '/search/',
        checkoutPageUS: isDevelopment ? '/content/sunbeltrentals/us/en_US/checkout-page.html/' : '/checkout-page/',
        checkoutPageCA: isDevelopment ? '/content/sunbeltrentals/us/en_US/checkout-page.html/' : '/checkout-page/',
        p2pFavoritesListPage: isDevelopment ? `/my-favorites.html` : '/my-favorites/',
        p2pFavoritesDetailPage: isDevelopment ? `/my-favorites.html/:id` : '/my-favorites/:id',
        p2pQuoteListPage: isDevelopment ? `/quotes.html` : '/quotes/',
        p2pPOListPage: isDevelopment ? `/purchaseorders.html` : '/purchaseorders/',
        p2pLandingPage: isDevelopment ? `punchout.html` : 'punchout',
        p2pCreateQuote: isDevelopment ? `/quote.html` : '/quote/',
        p2pEditQuote: isDevelopment ? '/quotes/edit-quote.html/' : '/quotes/edit-quote/',
        sbrMyAccount: '/myaccount/reservations/',
        storeDetailsUS: '/location/',
        storeDetailsCA: '/location/ca/',
        storeDetailsFTV: '/location/ftv/',
        signInHandler: (customRedirection, newAccount, includeReturnUrl) =>
            getSignInUrl(customRedirection, newAccount, includeReturnUrl),
        signout: isDevelopment
            ? 'https://dev-www.sunbeltrentals.com/login/logoff/'
            : `${window.location.origin}/login/logoff/`,
        tncCA: 'https://www.sunbeltrentals.com/about/terms-and-conditions-CA/',
        tncUS: 'https://www.sunbeltrentals.com/about/terms-and-conditions/',
        privacyPolicyCA: 'https://www.sunbeltrentals.com/legal/privacy-policy/privacy-policy-ca/',
        createAccount: isDevelopment
            ? `https://dev-www.sunbeltrentals.com/createaccount/customer/?returnurl=${window.location.href}`
            : `${window.location.origin}/createaccount/customer/?returnurl=${window.location.href}`,
        newCreateAccount: isDevelopment
            ? `https://dev-www.sunbeltrentals.com/createaccount/customer`
            : `${window.location.origin}/createaccount/customer`,
        wiresCrossedPage: '/error/',
        ccEquipmentPage: '/myaccount/equipment/',
        rppPage: '/resources/rental-protection-plan/',
        fuelPage: '/legal/terms-and-conditions/fees-and-optional-charges/',
        myAccount: '/myaccount/',
        webForms: '/webforms/',
        payment: '/payment/',
        createAccountMain: '/createaccount/',
        quickRentPage: isDevelopment
            ? `${window.location.origin}/content/sunbeltrentals/us/en_US.html/myaccount/quickrent/`
            : `${window.location.origin}/myaccount/quickrent/`,
        originURL: isDevelopment ? `${window.location.origin}/content/sunbeltrentals/us/en_US` : window.location.origin,
        equipmenRentalURL: isDevelopment
            ? '/content/sunbeltrentals/us/en_US/equipment-rental.html/'
            : '/equipment-rental/',
        ccaApplicationStatus: isDevelopment
            ? `/content/sunbeltrentals/language-masters/en/cca/application-approved.html?wcmmode=disabled`
            : 'cca',
        ccaApplicationApproved: isDevelopment
            ? `/content/sunbeltrentals/language-masters/en/cca/application-approved.html?wcmmode=disabled`
            : 'cca',
        ccaApplicationReview: isDevelopment
            ? `/content/sunbeltrentals/language-masters/en/cca/application-review.html?wcmmode=disabled`
            : 'cca',
        ccaWorkflow: ccaWorkflowSelector ? ccaWorkflowSelector.dataset.appbasepath : 'cca',
        ccaApplicationError: isDevelopment
            ? `/content/sunbeltrentals/language-masters/en/cca/application-error.html?wcmmode=disabled`
            : 'cca',
        ccaApplicationStatus: isDevelopment
            ? `/content/sunbeltrentals/language-masters/en/cca/application-status.html?wcmmode=disabled`
            : 'cca',
        guidedRegWorkflow: grWorkflowSelector ? grWorkflowSelector.dataset.appbasepath : '/createaccount/customer/',
        cashWorkflow: cashWorkflowSelector ? cashWorkflowSelector.dataset.appbasepath : '/createaccount/personal/',

        signInIframe: isDevelopment
            ? 'https://dev-www.sunbeltrentals.com/login/loginaem/?loginsource=aemheader'
            : '/login/loginaem/?loginsource=aemheader',
        oktaSignInIframe: isDevelopment
            ? 'https://dev-www.sunbeltrentals.com/login/loginaem/?returnUrl=/cart&loginsource=aemheader'
            : '/login/loginaem/?returnUrl=/cart&loginsource=aemheader',
        guidedSignInIframe: isDevelopment
            ? 'https://qa-www3.sunbeltrentals.com/login/loginaemgr/?returnUrl=/cca/personal-information&loginsource=grpage'
            : '/login/loginaemgr/?returnUrl=/cca/personal-information&loginsource=grpage',
        equipmentRentalPage: isDevelopment
            ? '/content/sunbeltrentals/us/en_US/equipment-rental.html'
            : '/equipment-rental/',
        cartPage: getCartPagePath(),
        managePO: isDevelopment
            ? '/content/sunbeltrentals/us/en_US/procure-to-pay/punchout/purchaseorders.html'
            : '/procure-to-pay/punchout/purchaseorders/'
    }
};
