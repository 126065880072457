import { useCartState } from '../contexts/cart/cartContext';
import { isFourHourRental } from '../components/global/utils/commonUtils';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { RESET_CLICKS, SET_CALENDAR_DATE_INTERACTION, SET_END_CLICK, SET_START_CLICK } from '../aem-core-components/actions/constants';

const useDatePicker = () => {
    const [{ cart }, dispatch] = useCartState();
    const onDateClick = (setIsEndDateEmpty, setIsStartDateEarly) => {
        dispatch({ type: SET_CALENDAR_DATE_INTERACTION });
        setIsEndDateEmpty(false);
        setIsStartDateEarly(false);
    };
    const resetDates = () => {
        dispatch({ type: RESET_CLICKS });
    };
    const skipErrorForSameDate = (endDate, startDate, showWarning) => {
        if (endDate == startDate && startDate) {
            if (isFourHourRental(cart) && showWarning) {
                // if is a four hr rental and warning is visible
                return true;
            }
        }
        return false;
    };
    const handleStartClick = (handleToggle, isOpen) => {
        dispatch({ type: SET_START_CLICK });
        if (!isOpen) {
            handleToggle(VARIABLE_CONFIG.EVENT.START_DATE_CLICK);
        }
    };
    const handleEndClick = (handleToggle, isOpen) => {
        dispatch({ type: SET_END_CLICK });
        if (!isOpen) {
            handleToggle(VARIABLE_CONFIG.EVENT.END_DATE_CLICK);
        }
    };
    const handleResetClick = () => {
        dispatch({ type: RESET_CLICKS });
    };
    return {
        handleResetClick,
        handleEndClick,
        handleStartClick,
        skipErrorForSameDate,
        resetDates,
        onDateClick
    };
};

export default useDatePicker;
