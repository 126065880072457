export const dynamicSeasonalProductsConstant = {
    grant_type: 'client_credentials',
    client_id: '0oa3cjs4xbyricVYP1d7',
    client_secret: 'ij1QFDycIOFktPM8Ct6HZ_LODX3Ou4aEb_vWpxXv',
    scope: 'Mulesoft',
    requestOrigin: 'SBRWEB',
    channel: 'B2B',
    defaultItemLength: 6,
    minproductLength : 5,
    accountnumber : 0,
    maxdistance:  10,
    maxresults: 10,
    utlscore: 0.8
};
