/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2021 Adobe
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
 import { gql } from '@apollo/client';

 const MUTATION_REPLACE_CART_ITEM = gql`
     mutation ($cartId: String!, $unavailableItemSku: String!, $similarItemSku: String!) {
         replaceUnavailableSku(cart_id: $cartId, unavailable_sku: $unavailableItemSku, similar_sku: $similarItemSku) {
             items {
                quantity
                sku
                name 
                uid
                product {
                    isgreenleaf
                    greenleafdata
                    thumbnail {
                        url
                    }
                }
            }
            total_quantity
         }
     }
 `;
 export default MUTATION_REPLACE_CART_ITEM;
