import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFilterState } from '../../cap';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { getStoreDistances } from '../../dstTrainer/api/getDstTrainerAPIs';
import Button from '../../global/atoms/button';
import { useCheckUser } from '../../../hooks/useCheckUser';
import GetDirectionIcon from '../../../resources/images/get-direction.svg';
import SmartphoneIcon from '../../../resources/images/smartphone.svg';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { logError } from '../../global/utils/logger';
import { BackHeader } from '../../global/atoms/backHeader';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { STORE_VARIANT } from '../../cap/constants';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { CLOSED } from '../constants';

const StoreDetails = props => {
    const {
        storeDetailData,
        setOnStoreDetailsClick,
        handleChooseThisStoreClick,
        onPressGetDirection,
        variant,
        mediaType,
        className,
        companyId,
        hideFooterButtons,
        showBackHeader
    } = props;
    const intl = useIntl();
    const [{ viewCart, projectDetails }] = useFilterState();
    const [storeDistance, setStoreDistance] = useState(-1);
    const [storeDistanceUnit, setStoreDistanceUnit] = useState('');
    const { sendEventsForClick } = useAnalyticsContext();
    const { distanceText } = useCapUtils();
    const userType = useCheckUser();
    const isMobile = mediaType === MEDIA_TYPE.MOBILE;

    let phoneNo = storeDetailData.phone;
    if (storeDetailData?.phone) {
        phoneNo = phoneNo.substr(1, 3) + '-' + phoneNo.substr(6, 8);
    }
    const hrefphone = `tel:${phoneNo}`;

    useEffect(() => {
        if (variant !== STORE_VARIANT.CAP_HEADER) {
            getStoreDistance();
        }
    }, [variant, userType, storeDetailData]);

    const latSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails?.selectedProjectLatititude;
        } else return viewCart?.lat;
    };

    const longSelection = isCreditCustomer => {
        if (isCreditCustomer) {
            return projectDetails?.selectedProjectLongitude;
        } else return viewCart?.long;
    };

    const getStoreDistance = async () => {
        try {
            const data = await getStoreDistances(
                {
                    lat: Number(latSelection(userType === USER_TYPE.CREDIT)),
                    long: Number(longSelection(userType === USER_TYPE.CREDIT))
                },
                { lat: storeDetailData?.latitude, long: storeDetailData?.longitude }
            );
            if (data?.data?.length > 0) {
                setStoreDistance(data?.data[0]?.distance);
                setStoreDistanceUnit(data?.data[0]?.distanceUnit);
            }
        } catch (error) {
            logError(error, false, 'getStoreDistance');
        }
    };

    const sendEvents = () => {
        setOnStoreDetailsClick(false);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0].pc} ${VARIABLE_CONFIG.EVENT_LABEL.BACK}`
            );
        } catch (error) {
            logError(error, false, 'sendEvents');
        }
    };

    const onChooseStoreClick = () => {
        handleChooseThisStoreClick();
        setOnStoreDetailsClick(false);
    };

    const renderFooterButtons = () => {
        if (variant == STORE_VARIANT.CAP_HEADER) {
            return (
                <div className="modal-map-rightfooter">
                    <Button
                        type="button"
                        className="button button-primary button-block"
                        onClick={onChooseStoreClick}
                        buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}
                        data-testid={checkoutDatalocator.handleChooseThisStoreClick}>
                        {intl.formatMessage({ id: 'checkout:choose-this-store' })}
                    </Button>
                </div>
            );
        } else {
            return (
                <div className="modal-map-storedetailsfooter col-2-diff">
                    <Button
                        className="button button-outline-primary modal-alert-buttons-outlineborder"
                        onClick={sendEvents}
                        buttonAriaLabel={intl.formatMessage({
                            id: 'checkout:accessories-and-addons-skip-go-back'
                        })}>
                        {intl.formatMessage({ id: 'cart:back-btn-text' })}
                    </Button>
                    <Button
                        className="button button-primary"
                        onClick={onChooseStoreClick}
                        buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}>
                        {intl.formatMessage({ id: 'checkout:choose-this-store' })}
                    </Button>
                </div>
            );
        }
    };

    return (
        <div className={`${isMobile ? 'modal-map-right-mobile' : ''} modal-map-right ${className || ''}`}>
            {showBackHeader && <BackHeader closeModal={sendEvents} />}
            <div className="modal-map-storedetails">
                <div className="modal-map-storedetails-title">
                    {variant === STORE_VARIANT.CAP_HEADER ? (
                        <h6 className="text-capitalise">
                            {storeDetailData?.city?.toLowerCase()} {storeDetailData?.state} -{' '}
                            {intl.formatMessage({ id: 'store-details:branch' })}
                            {storeDetailData?.pc}
                        </h6>
                    ) : (
                        <h6>{storeDetailData?.name}</h6>
                    )}
                </div>
                <div className="modal-map-storedetails-distance">
                    {variant === STORE_VARIANT.CAP_HEADER ? (
                        <>
                            <div>
                                {storeDetailData?.specialties?.[0] ||
                                    intl.formatMessage({ id: 'location-card:general-equipText' })}
                            </div>
                            <div>
                                {Number(storeDetailData?.distance).toFixed(2)} {distanceText(companyId)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                {intl.formatMessage({ id: 'store-details:branch' })}
                                {storeDetailData?.pc}
                            </div>
                            {storeDistance && storeDistanceUnit && (
                                <div>
                                    {Number(storeDistance).toFixed(2)} {storeDistanceUnit}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="modal-map-storedetails-phone">
                    <SmartphoneIcon />
                    <a href={hrefphone}>{phoneNo}</a>
                </div>
                <div className="modal-map-storedetails-address">
                    <span className="modal-map-storedetails-address-line1">{`${storeDetailData.street},`}</span>
                    <span className="modal-map-storedetails-address-line2">
                        {`${storeDetailData.city}, ${storeDetailData.state}, ${storeDetailData.zip}`}
                    </span>
                </div>

                <Button
                    onClick={onPressGetDirection}
                    type="button"
                    className={
                        'button button-outline-primary button-block modal-alert-buttons-outlineborder modal-map-storedetails-button'
                    }
                    buttonAriaLabel={intl.formatMessage({ id: 'store-details:get-directions' })}>
                    <GetDirectionIcon className="modal-map-storedetails-button-mapicon" />{' '}
                    {intl.formatMessage({ id: 'store-details:get-directions' })}
                </Button>
            </div>
            <div className={`${isMobile ? 'modal-map-storehours-mobile' : ''} modal-map-storehours`}>
                <div className="modal-map-storehours-title">
                    <h6>{intl.formatMessage({ id: 'store-details:hours-of-operation' })}</h6>
                </div>

                {storeDetailData?.operatingHours?.map((data, index) => (
                    <div className="modal-map-storehours-hours" key={`${data?.day}${index}`}>
                        <span>{data?.day}</span>
                        <span>{data?.hours === CLOSED ? data?.hours : data?.hours?.toUpperCase()}</span>
                    </div>
                ))}
            </div>
            {!hideFooterButtons && renderFooterButtons()}
        </div>
    );
};

export default StoreDetails;
