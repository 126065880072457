import axios from 'axios';
import { API_CONFIG } from '../../../../../constants/apiConfig';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { frontendConfig } from '../../../utils/commonUtils';
import { generateCorrelationId, logError } from '../../../utils/logger';
import { dynamicSeasonalProductsConstant } from '../dynamicSeasonalProductsConstant';
import { cookieValue } from '../../../../../aem-core-components/utils/cookieUtils';

const getLoginToken = () => {
    return (
        JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.USER_LOGIN))?.loginToken ||
        cookieValue(STORAGE_CONFIG.COOKIES.LOGINTOKEN) ||
        ''
    );
};

export const getDynamicProducts = async (month, maxdistance, maxresults, utlscore, latitute, longitude,setCountryCode) => {
    const { GET_DYNAMIC_SESSIONAL_PRODUCTS } = API_CONFIG;
    try {
        const getAuthAccessToken = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.AUTHACCESSTOKEN);
        const { data, error } = await axios.get(
            `${frontendConfig?.muledomain}${GET_DYNAMIC_SESSIONAL_PRODUCTS}?latitude=${latitute}&longitude=${longitude}&distance=${maxdistance}&month=${month}&maxResults=${maxresults}&utlisationScoreWeight=${utlscore}`,
            {
                headers: {
                    account: dynamicSeasonalProductsConstant.accountnumber,
                    authorization: `Bearer ${getAuthAccessToken}`,
                    'x-correlation-Id': generateCorrelationId(),
                    companyId:setCountryCode,
                    loginToken: getLoginToken(),
                    requestOrigin: `${dynamicSeasonalProductsConstant?.requestOrigin}`,
                    channel: `${dynamicSeasonalProductsConstant?.channel}`,
                    isSilentOnError: true,
                    client_id: frontendConfig?.openidmuleclientid || dynamicSeasonalProductsConstant?.client_id,
                    client_secret:
                        frontendConfig?.openidmuleclientsecret || dynamicSeasonalProductsConstant?.client_secret
                }
            }
        );
        return { data, error };
    } catch (error) {
        logError(error,false,'getDynamicProducts');
        return {}
    }
};
