import {
    SET_FULFILLMENT_TYPE_FILTER,
    SET_IS_LOADING,
    SET_QUOTES_ERROR,
    SET_QUOTE_LIST,
    SET_UPDATED_QUOTE_LIST,
    SET_STATUS_FILTER,
    SET_RENTAL_START_DATE,
    SET_ESTIMATED_RENTAL_DATE,
    SET_ACCOUNTS_FILTER,
    SET_ACCOUNTS_SELECTION,
    SET_JOBSITES_FILTER,
    SET_JOBSITES_SELECTION,
    SET_ACCOUNT_LIST,
    SET_JOBSITE_LIST,
    SET_ACCOUNT_NUMBER,
    SET_CLEAR_FILTER,
    SET_ORDERED_BY_LIST,
    SET_ORDERED_BY_FILTER,
    SET_ARE_FILTERS_RESET,
    SET_IS_APPLY_SELECTION_CTA_CLICKED,
    SET_IS_ACCOUNTS_LOADED,
    SET_INITIAL_ACCOUNTS_LIST,
    SET_INITIAL_JOBSITES_LIST,
    SET_QUOTE_DETAILS,
    SET_IS_TRANSMITTED,
    SET_TRANSMITTED_QUOTE_ID,
    SET_SALESFORCE_QUOTE_ID,
    SET_SELECTED_QUOTE_RATES,
    SET_IS_REDIRECTED_QUOTE,
    QUOTE_ORDER_ESTIMATES,
    QUOTE_ESTIMATES_LOADED,
    SET_TRANSMIT_QUOTES,
    SET_QUOTE_SUMMARY,
    SET_QUOTE_SEARCH_TERM,
    SET_CREATEDBY_TYPE_FILTER
} from '../actionTypes/actionTypes';

export const quotesInitialState = {
    quoteList: [],
    updatedQuoteList: [],
    error: null,
    isLoading: false,
    accountNumber: null,
    selectedFilters: {
        accounts: [],
        jobsites: [],
        status: { isTransmitted: false, isUnTransmitted: false },
        rentalStartDateRange: { startDate: null, endDate: null },
        estimatedRentalEndDateRange: { startDate: null, endDate: null },
        fulfillmentType: { isRoundTrip: false, isInStore: false },
        createdBy: { isSBREmployee: false, isSBRPunchout: false },
        orderedBy: []
    },
    areFiltersReset: false,
    selectedAccounts: [],
    selectedJobsites: [],
    accountList: [],
    jobsiteList: [],
    initialAccounts: [],
    initialJobsites: [],
    orderedByList: [],
    isApplyCTAClicked: false,
    isAccountsLoaded: false,
    quoteDetailsData: {},
    selectedQuoteRates: {},
    isTransmitting: false,
    transmittedQuoteId: null,
    salesforceQuoteId: null,
    isRedirectQuote: false,
    transmitQuotes: {},
    quoteSummary: {},
    quoteOrderEstimates: {},
    quoteSearchTerm: '',
    quoteEstimatesLoaded: false
};

export const quotesReducer = (state, action) => {
    switch (action.type) {
        case SET_QUOTE_LIST:
            return {
                ...state,
                quoteList: action.payload
            };

        case SET_UPDATED_QUOTE_LIST:
            return {
                ...state,
                updatedQuoteList: action.payload
            };
        case SET_IS_ACCOUNTS_LOADED:
            return {
                ...state,
                isAccountsLoaded: action.payload
            };

        case SET_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumber: action.payload
            };

        case SET_ACCOUNT_LIST:
            return {
                ...state,
                accountList: action.payload
            };

        case SET_INITIAL_ACCOUNTS_LIST:
            return {
                ...state,
                initialAccounts: action.payload
            };

        case SET_INITIAL_JOBSITES_LIST:
            return {
                ...state,
                initialJobsites: action.payload
            };
        case SET_JOBSITE_LIST:
            return {
                ...state,
                jobsiteList: action.payload
            };

        case SET_QUOTES_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };

        case SET_ACCOUNTS_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    accounts: [...action.payload]
                }
            };

        case SET_ACCOUNTS_SELECTION:
            return {
                ...state,
                selectedAccounts: [...action.payload]
            };

        case SET_JOBSITES_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    jobsites: [...action.payload]
                }
            };

        case SET_JOBSITES_SELECTION:
            return {
                ...state,
                selectedJobsites: [...action.payload]
            };
        case SET_STATUS_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    status: action.payload
                    }
                };
        case SET_FULFILLMENT_TYPE_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    fulfillmentType: action.payload
                }
            };

        case SET_RENTAL_START_DATE:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    rentalStartDateRange: action.payload
                }
            };

        case SET_ESTIMATED_RENTAL_DATE:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    estimatedRentalEndDateRange: action.payload
                }
            };
        case SET_CLEAR_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...quotesInitialState.selectedFilters
                },
                selectedAccounts: [],
                selectedJobsites: [],
            };
        case SET_ARE_FILTERS_RESET:
            return {
                ...state,
                areFiltersReset: action.payload
            };
        case SET_ORDERED_BY_LIST:
            return {
                ...state,
                orderedByList: action.payload
            };
        case SET_ORDERED_BY_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    orderedBy: [...action.payload]
                }
            };
        case SET_IS_APPLY_SELECTION_CTA_CLICKED:
            return {
                ...state,
                isApplyCTAClicked: action.payload
            };
        case SET_QUOTE_DETAILS:
            return {
                ...state,
                quoteDetailsData: action.payload
            };
        case SET_TRANSMITTED_QUOTE_ID:
            return {
                ...state,
                transmittedQuoteId: action.payload
            };
        case SET_SALESFORCE_QUOTE_ID:
            return {
                ...state,
                salesforceQuoteId: action.payload
            };
        case SET_IS_TRANSMITTED:
            return {
                ...state,
                isTransmitting: action.payload
            };
        case SET_IS_REDIRECTED_QUOTE:
            return {
                ...state,
                isRedirectQuote: action.payload
            };
        case SET_SELECTED_QUOTE_RATES:
            return {
                ...state,
                selectedQuoteRates: action.payload
            };
        case QUOTE_ORDER_ESTIMATES:
            return {
                ...state,
                quoteOrderEstimates: action.payload
            };
        case QUOTE_ESTIMATES_LOADED:
            return {
                ...state,
                quoteEstimatesLoaded: action.payload
            };
        case SET_TRANSMIT_QUOTES:
            return {
                ...state,
                transmitQuotes: action.payload
            };
        case SET_QUOTE_SUMMARY:
            return {
                ...state,
                quoteSummary: action.payload
            };

        case SET_QUOTE_SEARCH_TERM:
            return {
                ...state,
                quoteSearchTerm: action.payload
            };
        case SET_CREATEDBY_TYPE_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    createdBy: action.payload
                }
            };
        default:
            return state;
    }
};
