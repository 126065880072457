import {
    SET_PURCHASE_ORDER_LIST,
    SET_UPDATED_PURCHASE_ORDER_LIST,
    SET_PURCHASE_ORDER_LIST_RESPONSE,
    SET_PURCHASE_ORDER_LIST_ERROR,
    SET_IS_LOADING,
    SET_ACCOUNT_LIST,
    SET_ACCOUNTS_FILTER,
    SET_ACCOUNTS_SELECTION,
    SET_PO_NUMBER_SELECTION,
    SET_ACCOUNTS_AND_PO_NUMBER_FILTER,
    SET_ACCOUNT_NUMBER,
    SET_ISSUED_DATE_RANGE,
    SET_CLEAR_FILTER,
    SET_ARE_FILTERS_RESET,
    SET_IS_APPLY_SELECTION_CTA_CLICKED,
    SET_PURCHASE_ORDER_DETAILS,
    SET_IS_REDIRECTED_PO,
    SORT_TYPE,
    SET_PURCHASE_ORDER_ACCOUNT_LIST,
    SET_PURCHASE_ORDER_PO_NUMBER_LIST,
    SET_SELECTED_ACCORDION,
    SET_IS_ACCOUNTS_CHANGED,
    SET_APPLY_FILTERS_CTA,
    SET_PO_SEARCH_KEY_FLAG,
    SET_PRIVIOUS_SELECTED_FILTER,
    SET_FILTER_DATA
} from '../actionTypes/actionTypes.js';
import { SORT_DESCENDING } from '../constant.js';

export const PurchaseOrderListInitialState = {
    purchaseOrderList: [],
    updatedPurchaseOrderList: [],
    purchaseOrderListResponse: {},
    error: null,
    isLoading: false,
    accountNumber: null,
    selectedFilters: {
        accounts: [],
        poNumber: [],
        issuedDateRange: { startDate: '', endDate: '' }
    },
    areFiltersReset: false,
    selectedAccordion: '',
    selectedAccounts: [],
    selectedPONumber: [],
    selectedJobsites: [],
    filterAccountList: [],
    isApplyCTAClicked: false,
    isAccountsChanged: false,
    PODetailsData: {},
    isRedirectPO: false,
    sortOrder: `po_date-${SORT_DESCENDING}`,
    initialAccounts: [],
    initialPONumbers: [],
    poSearchKeyFlag: false,
    applyCTAClicked: false,
    pOFilterData: []
};

export const purchaseOrderListReducer = (state, action) => {
    switch (action.type) {
        case SET_PURCHASE_ORDER_LIST:
            return {
                ...state,
                purchaseOrderList: action.payload
            };

        case SET_UPDATED_PURCHASE_ORDER_LIST:
            return {
                ...state,
                updatedPurchaseOrderList: action.payload
            };
        case SET_PURCHASE_ORDER_LIST_RESPONSE:
            return {
                ...state,
                purchaseOrderListResponse: action.payload
            };
        case SET_ACCOUNT_NUMBER:
            return {
                ...state,
                accountNumber: action.payload
            };
        case SET_PURCHASE_ORDER_LIST_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case SORT_TYPE:
            return {
                ...state,
                sortOrder: action.payload
            };
        case SET_ACCOUNTS_SELECTION:
            return {
                ...state,
                selectedAccounts: [...action.payload]
            };
        case SET_PO_NUMBER_SELECTION:
            return {
                ...state,
                selectedPONumber: [...action.payload]
            };
        case SET_ACCOUNTS_FILTER: // Currently not using anywhere we can remove while cleanup
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    accounts: [...action.payload]
                }
            };
        case SET_ACCOUNTS_AND_PO_NUMBER_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [action.key]: [...action.payload]
                }
            };
        case SET_IS_ACCOUNTS_CHANGED:
            return {
                ...state,
                isAccountsChanged: action.payload
            };
        case SET_ISSUED_DATE_RANGE:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    issuedDateRange: action.payload
                }
            };
        case SET_CLEAR_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...PurchaseOrderListInitialState.selectedFilters
                },
                selectedAccounts: [],
                selectedPONumber: [],
            };
        case SET_ARE_FILTERS_RESET:
            return {
                ...state,
                areFiltersReset: action.payload
            };
        case SET_PURCHASE_ORDER_ACCOUNT_LIST:
            return {
                ...state,
                initialAccounts: action.payload
            };
        case SET_PURCHASE_ORDER_PO_NUMBER_LIST:
            return {
                ...state,
                initialPONumbers: action.payload
            };
        case SET_SELECTED_ACCORDION:
            return {
                ...state,
                selectedAccordion: action.payload
            };
        case SET_APPLY_FILTERS_CTA:
            return {
                ...state,
                applyCTAClicked: action.payload
            };
        case SET_PO_SEARCH_KEY_FLAG:
            return {
                ...state,
                poSearchKeyFlag: action.payload
            };
        case SET_FILTER_DATA:
            return {
                ...state,
                pOFilterData: action.payload
            };
        default:
            return state;
    }
};
