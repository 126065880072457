import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useAtp } from '../../../../cap/hooks/useATP';
import ProductTile from '../ProductTile';
import { PRODUCT_TILE_MOUNT_REACT } from '../../../utils/commonUtils';
import { PRODUCT_TILE_VARIANT } from '../../../constants';

const AddToCartProductTileReact = ({ cardData = {
    catclass: '',
    showonlinecatalog: 'YES',
    disableaddtocartoption: 'NO',
    catsku: '',
    inventory: ''
}, handleClick = () => {}, trackAnalytics = () => {}, handleAddToCartClickAnalytics = () => {}, isCoachmarkEnabled = true, isReplaceItem = false, variant = PRODUCT_TILE_VARIANT.GRID_VIEW, tileStatus = '',replaceSku=''}) => {
    const currentNode = useRef();
    const { handleATP } = useAtp();
    const [isLoading, setIsLoading] = useState(false);
    const [tileStatusValue, setTileStatusValue] = useState(tileStatus || '');

    useEffect(() => {
        if (currentNode?.current) {
            currentNode.current.dataset.productdata = JSON.stringify({ catclass: cardData?.catclass });
        }
    }, [cardData?.catclass]);

    useEffect(() => {
        if (!tileStatus && cardData?.catsku) {
            const status = handleATP({
                catsku: cardData?.catsku,
                inventoryDataObj: cardData?.inventory,
                showonlinecatalog: cardData?.showonlinecatalog,
                disableaddtocartoption: cardData?.disableaddtocartoption,
                pcAvailability: cardData?.pc_availability
            });
            setTileStatusValue(status);
        }
    }, [tileStatus, cardData, handleATP]);

    return (
        <div ref={currentNode} className={isLoading ? 'pdp__root-isloading' : PRODUCT_TILE_MOUNT_REACT}>
            <ProductTile
                tileDetails={cardData}
                tileState={tileStatusValue}
                handleClick={handleClick}
                trackAnalytics={trackAnalytics}
                handleAddToCartClickAnalytics={handleAddToCartClickAnalytics}
                isCoachmarkEnabled={isCoachmarkEnabled}
                isReplaceItem={isReplaceItem}
                variant={variant}
                replaceSku={replaceSku}
            />
        </div>
    );
};

AddToCartProductTileReact.propTypes = {
    cardData: shape({
        catclass: oneOfType([number, string]),
        showonlinecatalog: string,
        disableaddtocartoption: string,
        catsku: oneOfType([number, string]),
        inventory: string
    }),
    handleClick: func,
    trackAnalytics: func,
    handleAddToCartClickAnalytics: func,
    isCoachmarkEnabled: bool,
    isReplaceItem: bool,
    variant: string,
    tileStatus: string
};

export default memo(AddToCartProductTileReact);
