import React from 'react';
import { useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import Button from '../../../../atoms/button/button';
import ShoppingCart from '../../../../../../resources/images/carticon24.svg';
import { productTileDataLocator } from '../../../addToCartTile/dataLocators';

const AddToCart = ({ showCTA = false, isRequestQuoteCTA = false, handleClick = () => {}, className = '', productName = '', isReplaceItem = false }) => {
    const intl = useIntl();

    const handleBtnClick = () => {
        handleClick(isRequestQuoteCTA);
    };

    const showButtonAriaLabel = () => {
        if (isRequestQuoteCTA) {
            return `${intl.formatMessage({ id: 'pdp:request-quote' })} ${productName}`;
        } else if (isReplaceItem) {
            return `${intl.formatMessage({ id: 'cart:replace-button' })}`;
        } else {
            return `${intl.formatMessage({ id: 'product:add-item' })} ${productName}`;
        }
    }

    const showButtonlabel = () => {
        if (isRequestQuoteCTA) {
            return `${intl.formatMessage({ id: 'pdp:request-quote' })}`;
        } else if (isReplaceItem) {
            return  `${intl.formatMessage({ id: 'cart:replace-button' })}`;
        } else {
            return `${intl.formatMessage({ id: 'product:add-item' })}`;
        }
    }

    return (
        <div
            data-testid={productTileDataLocator.productTile_addToCartIcon}
            className={`card__plp-addtocart-wrap ${className}`}>
            {showCTA ? (
                <Button
                    className="button button-primary button-block producttile__addtocart-action"
                    type="button"
                    onClick={handleBtnClick}
                    dataTestid={productTileDataLocator.productTile_add_to_cart_CTA_testid}
                    buttonAriaLabel={showButtonAriaLabel()}>
                    {!isRequestQuoteCTA && <ShoppingCart className="producttile__addtocart-icon" />}
                    {showButtonlabel()}
                </Button>
            ) : (
                <div className="cart-item-price-empty"></div>
            )}
        </div>
    );
};

export default React.memo(AddToCart);

AddToCart.propTypes = {
    isRequestQuoteCTA: bool,
    showCTA: bool,
    handleClick: func,
    className: string,
    productName:string
};
